/**
 * Created by litian on 2019-04-02.
 */
'use strict';
import Vue from 'vue';
import DeviceMode from '../device/device-mode.vue';
import DeviceDetail from '../device/device-detail.vue';
import {addDomForId} from '../../libs/util';

function getDeviceModeVm(){
    addDomForId('mix-device-mode');
    let vm = new Vue({
        render: h => h(DeviceMode)
    }).$mount('#mix-device-mode');
    return vm.$children[0];
}

function getDeviceDetailVm(){
    addDomForId('mix-device-detail');
    let vm = new Vue({
        render: h => h(DeviceDetail)
    }).$mount('#mix-device-detail');
    return vm.$children[0];
}

let modeVm = getDeviceModeVm();
let detailVm = getDeviceDetailVm();
//console.log(vm.$children[0]);

export default {
    methods:{
        startReportedRate:modeVm.startReportedRate, //单个设置定位模式
        startBatchReportedRate:modeVm.startBatchReportedRate, //批量设置定位模式
        showDeviceDetail:detailVm.showDeviceDetail //设备详情
    }
}
