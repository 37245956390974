var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Modal",
        {
          attrs: { width: "650", title: "设备详情" },
          on: {
            "on-cancel": function($event) {
              return _vm.resetFields("dtlPop")
            }
          },
          model: {
            value: _vm.showDeviceDtlModal,
            callback: function($$v) {
              _vm.showDeviceDtlModal = $$v
            },
            expression: "showDeviceDtlModal"
          }
        },
        [
          _c(
            "Form",
            {
              ref: "dtlPop",
              attrs: { model: _vm.deviceDtl, "label-width": 90 }
            },
            [
              _c("div", { staticClass: "deviceLabel" }, [_vm._v("设备信息")]),
              _c("div", { staticClass: "line" }),
              _c(
                "Row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "Form-item",
                        { attrs: { label: "设备IMEI", prop: "device" } },
                        [
                          _c("Input", {
                            staticClass: "pop-width",
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.deviceDtl.device,
                              callback: function($$v) {
                                _vm.$set(_vm.deviceDtl, "device", $$v)
                              },
                              expression: "deviceDtl.device"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "Form-item",
                        { attrs: { label: "创建时间", prop: "createTime" } },
                        [
                          _c("Input", {
                            staticClass: "pop-width",
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.deviceDtl.createTime,
                              callback: function($$v) {
                                _vm.$set(_vm.deviceDtl, "createTime", $$v)
                              },
                              expression: "deviceDtl.createTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Row",
                [
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "Form-item",
                        { attrs: { label: "设备型号", prop: "deviceType" } },
                        [
                          _c("Input", {
                            staticClass: "pop-width",
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.deviceDtl.productModel,
                              callback: function($$v) {
                                _vm.$set(_vm.deviceDtl, "productModel", $$v)
                              },
                              expression: "deviceDtl.productModel"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "Form-item",
                        { attrs: { label: "到期时间", prop: "expireTime" } },
                        [
                          _c("Input", {
                            staticClass: "pop-width",
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.deviceDtl.expireTime,
                              callback: function($$v) {
                                _vm.$set(_vm.deviceDtl, "expireTime", $$v)
                              },
                              expression: "deviceDtl.expireTime"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("div", { staticClass: "deviceLabel" }, [_vm._v("SIM卡信息")]),
              _c("div", { staticClass: "line" }),
              _c(
                "Row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "Form-item",
                        { attrs: { label: "设备SIM卡号", prop: "sim" } },
                        [
                          _c("Input", {
                            staticClass: "pop-width",
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.deviceDtl.sim,
                              callback: function($$v) {
                                _vm.$set(_vm.deviceDtl, "sim", $$v)
                              },
                              expression: "deviceDtl.sim"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c(
                        "Form-item",
                        { attrs: { label: "ICCID", prop: "iccid" } },
                        [
                          _c("Input", {
                            staticClass: "pop-width",
                            attrs: { readonly: "" },
                            model: {
                              value: _vm.deviceDtl.iccid,
                              callback: function($$v) {
                                _vm.$set(_vm.deviceDtl, "iccid", $$v)
                              },
                              expression: "deviceDtl.iccid"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Row",
                [
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c("Form-item", { attrs: { label: "激活日期" } }, [
                        _vm._v(" " + _vm._s(_vm.deviceDtl.activationDate) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c("Form-item", { attrs: { label: "服务期止" } }, [
                        _vm._v(" " + _vm._s(_vm.deviceDtl.validDate) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "Row",
                [
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c("Form-item", { attrs: { label: "已使用流量" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.deviceDtl.used
                                ? (_vm.deviceDtl.used / 1024).toFixed(2)
                                : "0"
                            ) +
                            "MB "
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "12" } },
                    [
                      _c("Form-item", { attrs: { label: "剩余流量" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.deviceDtl.allowance
                                ? (_vm.deviceDtl.allowance / 1024).toFixed(2)
                                : "0"
                            ) +
                            "MB "
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "Button",
                {
                  attrs: { type: "primary", size: "large" },
                  on: {
                    click: function($event) {
                      return _vm.resetFields("dtlPop")
                    }
                  }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }